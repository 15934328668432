.platform-taxons-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    .item-label {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 3px;
      color: #646c9a;
    }
    .item-wrapper {
      display: flex;
      align-items: center;
    }
    .taxons-list {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 10px 15px;
      border-radius: 4px;
      border: 1px solid #e2e5ec;
      &__item {
        font-size: 13px;
        line-height: 20px;
        padding-right: 20px;
        color: #495057;
        &:not(:last-child) {
          position: relative;
          &::after {
            content: "";
            width: 6px;
            height: 6px;
            border: 1px solid #495057;
            border-bottom: 0;
            border-left: 0;
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-8px, -50%) rotate(45deg);
          }
        }
        &.current {
          font-weight: 500;
        }
      }
    }
    .taxons-remove-box {
      flex-basis: 50px;
      max-width: 50px;
      padding-left: 15px;
    }
  }
}
