.producer-basic-data {
  &-group {
    display: flex;
    width: 100%;
    max-width: 600px;
  }
  &-form {
    flex-basis: calc(100% - 130px);
    max-width: calc(100% - 130px);
    padding-right: 20px;
  }
  &-logo {
    flex-basis: 130px;
    max-width: 130px;
    &__label {
      font-size: 13px;
      line-height: 20px;
      color: #646c9a;
      margin-bottom: 5px;
    }
    .image-uploader {
      padding: 0;
      margin-bottom: 10px;
      &__box {
        margin: 0;
      }
    }
  }
}

.rc-md-navigation {
  background: #fff;
}

.rc-md-editor .editor-container .sec-md .input{
  padding-top:20px;
  &::-webkit-scrollbar{
    display: none;
  }
}

.rc-md-editor .tool-bar {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}