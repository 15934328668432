.category-list {
  display: flex;
  flex-flow: wrap;
  margin-top: 5px;
  .category-item {
    flex-basis: calc(25% - 10px);
    transition: 0.3s;
    cursor: pointer;
    background-color: #fff;
    margin: 5px;
    text-align: center;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    &__name {
      padding: 30px;
      display: block;
      color: gray;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
