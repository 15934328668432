.property-options {
  &__form {
    padding: 10px 0;
    border-bottom: 1px solid $separator;
    .form-row {
      width: 100%;
      margin: 0;
      .form-group {
        flex-basis: 25%;
        max-width: 25%;
        padding-right: 30px;
        margin: 0;
        &:first-child {
          padding-left: 28px;
        }
        &:last-child {
          margin-left: -5px;
        }
      }
    }
  }
  &__show {
    padding: 15px 30px;
  }
}
