.bullet-point-group {
  display: flex;
  align-items: flex-start;
  .order-buttons {
    margin: 0 25px;
  }
  .btn-icon {
    padding: 0;
  }
}
