.label-status {
  display: inline-block;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.81px;
  border-radius: 4px;
  padding: 0 8px;
  background: transparent;
  color: #fff;
  font-weight: 500;
  &--danger {
    background-color: rgba($color: #fd397a, $alpha: 0.2);
    color: #fd397a;
  }
  &--warning {
    background-color: rgba($color: #ffb822, $alpha: 0.2);
    color: #ffb822;
  }
  &--default {
    background-color: rgba($color: #595d6e, $alpha: 0.2);
    color: #595d6e;
  }
  &--success {
    background-color: rgba($color: #0abb87, $alpha: 0.2);
    color: #0abb87;
  }
}
