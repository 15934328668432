@import "~flag-icon-css/css/flag-icon.css";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~react-dropzone-uploader/dist/styles.css";
@import "reset.scss";
@import "colors.scss";
@import "mixins.scss";
@import "typography.scss";
@import "table.scss";
@import "utils.scss";
@import "wysiwig.scss";
@import "partials/dashboard.scss";
@import "partials/categories.scss";
@import "partials/producent.scss";
@import "partials/category_boxes.scss";
@import "partials/products.scss";
@import "partials/checkbox.scss";
@import "partials/producer.scss";
@import "partials/global_image_modal.scss";
@import "partials/label-status.scss";
@import "partials/specification_table.scss";
@import "partials/status-select.scss";
@import "partials/layout.scss";

@import "expano_components.scss";
@import "partials/loaders.scss";
@import "partials/lang_tabs.scss";
@import "partials/property_options.scss";
@import "partials/prices_group.scss";
@import "partials/preview_button.scss";
@import "partials/product_card.scss";
@import "partials/product_card_sections.scss";
@import "partials/rotate_modal.scss";
@import "partials/comments_modal.scss";
@import "partials/gallery_modal.scss";
@import "partials/allegro_channel_form.scss";
@import "partials/main_table.scss";
@import "partials/bullet-point-group.scss";
@import "partials/table-export-status.scss";
@import "partials/actions-group.scss";
@import "partials/platform-taxons-list.scss";
@import "partials/top-line-actions.scss";
@import "partials/main-list.scss";
@import "partials/tabs.scss";

.dzu-dropzone {
  &::-webkit-scrollbar {
    display: none;
  }
}
.popup.xl {
  max-width: 900px;
}
.popup.lg {
  max-width: 650px;
}

.tool-bar {
  display: none;
}

[class*="indicatorContainer"] {
  padding: 0 !important;
}

.form-group {
  [class*="indicatorContainer"] {
    padding: 0 10px !important;
  }
}


.se-container {
  z-index: 0;
}
