.create-table {
  flex: 1;
  min-height: calc(100vh - 138px);
  background-color: #fff;
  padding: 0 20px;
  form {
    width: 100%;
  }
  table {
    width: 100%;
    thead {
      text-align: left;
      border-bottom: 1px solid #ebedf2;
      th {
        width: 25%;
        padding: 18px 10px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #b6b8bf;
        &:last-child {
          text-align: right;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #ebedf2;
        td {
          width: 25%;
          vertical-align: middle;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          padding: 10px;
          color: #595d6e;
          &:last-child {
            text-align: right;
          }
          .check-group {
            margin: 0;
          }
          .form-group {
            max-width: 200px;
          }
          .create-table-btn {
            padding: 5px 0 2px;
            display: inline-flex;
            align-items: center;
            transition: 0.2s;
            svg {
              margin-right: 10px;
            }
            span {
              font-size: 13px;
              line-height: 20px;
              color: #5d78ff;
            }
            &:hover {
              opacity: 0.8;
            }
          }
          .text-info {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }
  &__footer {
    padding: 15px 0;
    text-align: right;
  }
}
