.languages-form {
  width: calc(100% + 60px);
  margin-left: -30px;
}
.lang-tabs {
  display: flex;
  border-bottom: 1px solid $separator;
  &__item {
    cursor: pointer;
    text-align: center;
    position: relative;
    &__button {
      padding: 15px 25px;
      width: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      z-index: 2;
      height: 1px;
      left: 50%;
      right: 50%;
      background-color: #597dff;
      transition: 0.3s;
    }
    &__flag {
      margin-right: 10px;
    }
    &__title {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #48465b;
    }
    &.active,
    &:hover {
      &:after {
        left: 0;
        right: 0;
      }
    }
  }
}
.lang-panels {
  padding: 20px 30px 0;
  &__item {
    display: none;
    &.active {
      display: block;
    }
  }
}
