.product-card-container {
  .dashboard {
    margin-bottom: 0;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: 1px solid #ebedf2;
    }
  }
}

.gallery-item {
  border-bottom: 1px solid #ebedf2;
  padding: 15px 0;
  &.open {
    .gallery-item__header__title {
      .btn-collapse {
        &::after {
          opacity: 0;
        }
      }
    }
    .gallery-item__body {
      display: block;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &__title {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 24px;
      .offer-dot {
        cursor: pointer;
        display: inline-block;
        margin-left: auto;
        margin-right: 5px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #0abe8c;
        &.disabled {
          pointer-events: none;
          background-color: #fd397a;
        }
      }
      .btn-collapse {
        width: 10px;
        height: 10px;
        border: 1px solid #5d78ff;
        background: #fff;
        position: absolute;
        left: 0;
        top: calc(50% - 5px);
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #5d78ff;
        }
        &::before {
          width: 4px;
          height: 1px;
        }
        &::after {
          width: 1px;
          height: 4px;
          opacity: 1s;
          transition: 0.2s;
        }
      }
      .circle-box {
        padding: 4px;
      }
      span {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        margin-right: 5px;
        &.sku {
          color: #a2a5b9;
        }
        .name {
          color: #595d6e;
        }
      }
    }
    &__info {
      display: flex;
      align-items: center;
      p {
        font-size: 13px;
        line-height: 20px;
        color: #646c9a;
        margin-right: 5px;
      }
      .label {
        display: inline-block;
        border-radius: 2px;
        color: #fff;
        font-size: 13px;
        line-height: 15px;
        padding: 2.5px 5px;
        margin: 0 4px;
        min-width: 20px;
        text-align: center;
        background-color: #5d78ff;
        &.danger {
          background-color: #fd397a;
        }
      }
    }
  }
  &__body {
    padding-top: 15px;
    display: none;
  }
  &__images {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin: -5px 0 0 -5px;
    .grid-item {
      display: block;
      width: 80px;
      height: 80px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
      margin: 5px;
    }
    .single-image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .overlay {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(93, 120, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 6px;
        transition: 0.2s;
        svg {
          max-width: 20px;
          path {
            &:not([fill="none"]),
            &[fill] {
              fill: #fff;
            }
          }
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2px;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          &.edit {
            background-color: rgba(68, 178, 241, 1);
          }
          &.remove {
            background-color: rgba(253, 57, 122, 1);
          }
          &.success {
            background-color: #0abe8c;
          }
        }
        .row {
          display: flex;
          align-items: center;
        }
      }
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
      &.with_feed {
        .overlay {
          align-items: flex-start;
        }
      }
      &.is_feed {
        overflow: visible;
        .feed-icon {
          position: absolute;
          z-index: 10;
          top: -4px;
          right: -4px;
          display: block;
          width: 14px;
          height: 14px;
        }
      }
    }
    .new-image {
      transition: 0.2s;
      &:hover {
        border-width: 2px;
      }
      &:active {
        transform: scale(0.95);
      }
      p {
        font-size: 13px;
        line-height: 20px;
        color: rgba(162, 165, 185, 1);
      }
    }
    .delete-image {
      transition: 0.2s;
      border-color: #fd397a;
      &:hover {
        border-width: 2px;
      }
      &:active {
        transform: scale(0.95);
      }
      p {
        font-size: 13px;
        line-height: 20px;
        color: #fd397a;
      }
    }
  }
}

.rotate-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__action {
    display: flex;
    flex-direction: column;
    .btn-blue {
      justify-content: center;
    }
    .btn-link {
      margin-top: 5px;
      font-size: 11px;
      line-height: 20px;
      font-weight: 300;
      color: #597dff;
      transition: 0.2s;
      &:hover {
        color: #0abe8c;
      }
    }
  }
  &__grid {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    max-width: 400px;
    margin-left: -2.5px;
    margin-top: 15px;
    .rotate-box {
      width: 28px;
      height: 28px;
      border-radius: 4px;
      background: #f7f8fa;
      margin: 2.5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      span {
        font-size: 13px;
        line-height: 20px;
        color: #a2a5b9;
      }
      &__background {
        border: 1px solid #e2e5ec;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
      }
    }
  }
}

.placeholders-box {
  padding: 20px;
  &__group {
    margin-bottom: 24px;
    .group-title {
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      margin-bottom: 10px;
      color: #b6b8bf;
    }
    .group-items {
      p {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 2px;
        color: #595d6e;
      }
    }
  }
}
