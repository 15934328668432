.popup-rotate {
  margin: auto;
  width: 100%;
  opacity: 0;
  transition: 0.2s;
  padding: 60px;
  overflow-y: scroll;
  max-height: 100vh;
  &.active {
    opacity: 1;
  }
}

.rotate {
  &_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 13px;
    .popup-rotate-box {
      width: 36px;
      height: 36px;
      background: rgba(89, 125, 255, 1);
      box-shadow: 0px 3px 16px rgba(89, 125, 255, 0.15);
      border-radius: 4px;
      font-size: 15px;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
  &_images {
    flex-basis: 650px;
    max-width: 650px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    &__grid {
      display: flex;
      flex-flow: wrap;
      padding: 20px 30px;
      margin-left: -10px;
      .grid-item {
        flex-basis: 120px;
        max-width: 120px;
        width: 100%;
        margin: 10px;
        position: relative;
        .order-label {
          position: absolute;
          top: 0;
          right: 0;
          svg {
            display: none;
          }
          &:hover {
            background-color: #fd397a;
            svg {
              display: inline-block;
            }
            span {
              display: none;
            }
          }
        }
        .image {
          height: 120px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border: 1px solid rgba(226, 229, 236, 1);
          border-radius: 4px;
        }
        .name {
          font-size: 10px;
          line-height: 20px;
          font-weight: 300;
          color: rgba(89, 93, 110, 1);
        }
      }
    }
  }
  &_list {
    flex-basis: calc(100% - 650px);
    max-width: calc(100% - 650px);
    padding-left: 20px;
    position: sticky;
    top: 0px;
    &__header {
      padding: 20px 0;
      border-bottom: 1px solid rgba(235, 237, 242, 1);
      p {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
      }
    }
    &__grid {
      display: flex;
      flex-flow: wrap;
      width: calc(100% + 20px);
      margin-left: -10px;
    }
    &__item {
      margin: 10px;
      cursor: pointer;
    }
  }
}
