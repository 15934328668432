.main-dashboard {
  min-height: calc(100vh - 40px);
  background-color: $dashboard-background;
}

.main-wrapper {
  display: flex;
  min-height: calc(100vh - 106px);
  background-color: #fff;
  .box-300 {
    flex-basis: 300px;
    max-width: 300px;
  }
  .box-400 {
    flex-basis: 400px;
    max-width: 400px;
  }
  .box-full {
    flex: 1;
  }
}

.dashboard {
  background-color: white;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.047);
  margin-bottom: 10px;
  &.fetching {
    .dashboard__list,
    .dashboard__body,
    .table__body {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  &.fh {
    height: 100%;
  }
  &.br {
    border-right: 1px solid #ebedf2;
  }
  &--nav {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none;
  }
  &--small {
    flex-basis: 49.2%;
  }
  &--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &__button-section {
    padding: 20px 30px;
  }
  &__list {
    &-item {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      position: relative;
      .country {
        margin-right: auto;
        &-label--hidden {
          visibility: hidden;
        }
      }
      .no-margin {
        margin: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $separator;
      }
    }
  }
  &__body {
    padding: 30px;
  }
  &__form {
    padding: 30px;
    &__submit {
      padding: 20px 30px;
    }
    &__footer {
      border-top: 1px solid $separator;
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px;
      &.md {
        justify-content: flex-start;
        .box {
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    &--pr-10 {
      //padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__form-items {
    width: 88%;
    display: flex;
    align-items: center !important;
    .header-form-item-number {
      flex-basis: 10%;
      margin-right: auto;
      margin-bottom: 0px !important;
    }
    .header-form-item-type {
      flex-basis: 30%;
      margin-left: 20px;
      margin-bottom: 0px !important;
      &__label {
        [fill] {
          fill: #5d78ff;
        }
        svg {
          margin-right: 10px;
        }
      }
    }
  }
  &__section-footer {
    padding: 20px 30px;
    border-top: 1px solid $separator;
    display: flex;
    .text {
      margin-right: 10px;
      color: #646c9a;
    }
    img {
      vertical-align: middle;
    }
    .item {
      padding-bottom: 12px;
    }
  }
  &__channel-label {
    padding: 30px;
    padding-top: 0;
    .channel-label-text {
      margin-bottom: 11px;
    }
  }
  &__header {
    display: flex;
    padding: 8px 30px 10px;
    min-height: 60px;
    align-items: center;
    border-bottom: 1px solid $separator;
    &--right {
      justify-content: flex-end;
    }
    &--edit {
      padding: 18px 30px;
      &--nav {
        box-shadow: 0px 0px 13px #0000000d;
        z-index: 1;
        &:after {
          display: none;
        }
      }
    }
  }
  &__title {
    margin-right: auto;
    margin-left: 10px;
    &--nav {
      font-weight: 800;
      font-size: 13px;
    }
    &--min-width {
      min-width: 70px;
    }
  }
  &__searchfield {
    padding: 18px 30px;
    display: flex;
    .search-box {
      flex: 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
    }
  }
  &__tabs {
    display: flex;
    background-color: $dashboard-background;
    .tab {
      flex-basis: 350px;
      background-color: $dashboard-tab-bg;
      padding: 9px 0;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:before {
        content: "";
        height: 18px;
        width: 1px;
        background-color: $dashboard-tab-separator;
        position: absolute;
        right: 0;
        bottom: 50%;
        transform: translateY(50%);
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
      &:hover {
        background-color: $dashboard-tab-bg-hover;
      }
      &.active {
        background-color: white;
        position: relative;
        &:hover {
          background-color: white;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $dashboard-tab-border;
          height: 2px;
        }
        &:before {
          display: none;
        }
      }
    }
  }
  &__grid-2 {
    display: flex;
    .column {
      flex-basis: calc(50%);
      &--2 {
        flex-basis: calc((100% / 3) * 2);
      }
      &--1 {
        flex-basis: calc(100% / 3);
      }
    }
  }
  &__editor {
    .DraftEditor-root {
      padding-top: 10px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $separator;
        width: calc(100% + 20px);
        margin-left: -10px;
      }
      button {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }
  &__export {
    padding: 25px 30px;
    padding-right: 66%;
  }
  &__footer {
    border-top: 1px solid $separator;
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px;
    .btn {
      margin-left: 10px;
    }
  }
}

.dashboard-container {
  width: 100%;
  margin-top: 30px;
  min-height: calc(100vh - 30px);
  //min-height: 100vh;
  background-color: $dashboard-background;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 70px;
}

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd;
}

.icon__container {
  svg {
    [fill] {
      fill: $svg-icon;
    }
    circle {
      fill: $svg-icon;
    }
  }
  .svg-rect {
    fill: $svg-icon;
  }
}

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.column-1 {
  flex-basis: calc(33.333% - 5px);

  &--container {
    display: flex;
    flex-direction: column;
  }
}

.column-2 {
  flex-basis: calc(66.666% - 5px);
}

.column-half {
  flex-basis: calc(50% - 5px);
}

.column-container {
  padding: 30px;
  &--pl-10 {
    padding-left: 10px;
  }
  .logo-list-label {
    margin-bottom: 4px;
  }
  .logo-list {
    display: flex;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    &__item {
      flex-basis: calc(30%);
      position: relative;
      overflow: hidden;
      border: 1px solid $separator;
      border-radius: 4px;
      margin: 10px;
      display: flex;

      .image-container {
        img {
          width: 100%;
        }
      }
      .item-wrapper {
        max-width: 60px;
        cursor: pointer;
        & > * {
          display: block;
          margin: auto;
          text-align: center;
        }
        margin: auto;
        span {
          color: #a2a5b9;
          margin-top: 10px;
        }
      }
      &:hover {
        .logo-list__item-overlay {
          opacity: 1;
        }
      }
      padding: 40px;
      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: 0.3s;
        bottom: 0;
        display: flex;
        color: white;
        path {
          fill: white;
        }

        background-color: #5d78ffd8;
        .overlay-container {
          margin: auto;
          text-align: center;
          cursor: pointer;
          //padding: 10px;
          width: 60px;
          span {
            display: block;
          }
          svg {
            display: block;
          }
          button {
            margin: 0;
          }
        }
        opacity: 0;
      }
    }
  }
}

.header-form-item-type__label {
  path {
    fill: #5d78ff;
  }
  svg {
    margin-right: 10px;
  }
}
