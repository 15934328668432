.actions-group {
  position: relative;
  .btn-actions {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease 0s;
    .dot {
      width: 4px;
      min-width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgb(181, 181, 195);
      margin: 0px 1.5px;
      transition: all 0.2s ease 0s;
    }
    &:hover {
      background-color: #f0f3ff;
      .dot {
        background-color: #5d78ff;
      }
    }
  }
  .actions-dropdown {
    z-index: 120;
    padding: 5px 10px;
    border-radius: 4px;
    min-width: 190px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(86, 63, 105, 0.15) 0px 0px 50px;
    position: absolute;
    right: 0px;
    top: 100%;
    &__item {
      width: 100%;
      margin: 5px 0px;
      padding: 10px 5px;
      display: flex;
      align-items: center;
      transition: all 0.2s ease 0s;
      svg {
        margin-right: 10px;
      }
      span {
        font-size: 13px;
        line-height: 20px;
        color: rgb(100, 108, 154);
        white-space: nowrap;
      }
    }
  }
}
