.producent-list {
  display: flex;
  margin-top: 10px;
  width: calc(100% + 20px);
  margin-left: -10px;
  flex-wrap: wrap;
  .producent {
    position: relative;
    overflow: hidden;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    flex-shrink: 200px;
    padding: 20px;
    flex-basis: calc((100% / 7) - 20px);
    max-width: calc((100% / 7) - 20px);
    &__image {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      img {
        max-width: 100%;
      }
    }
    &__name {
      font-size: 13px;
      line-height: 20px;
      margin-top: 10px;
      font-weight: 300;
      color: #595D6E;
    }
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.3s;
      cursor: pointer;
      bottom: 0;
      right: 0;
      background-color: none;
      display: flex;
      color: white;
      div {
        margin: auto;
        opacity: 0;
        span {
          padding-left: 5px;
          font-size: 13px;
          font-weight: 400;
        }
        path {
          fill: white;
        }
      }
    }
    &:hover {
      .producent__overlay {
        background-color: rgba(93, 120, 255, 0.8);
        div {
          opacity: 1;
        }
      }
    }
  }
}
