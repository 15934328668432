.status-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &__label {
    padding-left: 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-right: 10px;
    color: #595d6e;
  }
  .badge {
    border-radius: 2px;
    flex: 1;
    padding: 3px 10px;
    text-align: center;
    background-color: #b4b4b4;
    &.badge-option {
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
    }
    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #ffffff;
    }
    &.orange {
      background-color: #f2ae49;
    }
    &.yellow {
      background-color: #f9d101;
    }
    &.light-blue {
      background-color: #49b5f2;
    }
    &.blue {
      background-color: #4964f2;
    }
    &.green {
      background-color: #0abb87;
    }
    &.pink {
      background-color: #fd397a;
    }
  }
  [class*="indicatorContainer"] {
    width: 30px;
    justify-content: center;
  }
}
