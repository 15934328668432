$nav-item: #a2a5b9;
$nav-category: #4c4e6f;
$pink-light: #fd397a;
$pink-dark: #a40036;
$tab-text-active: #48465b;
$dashboard-background: #f2f3f8;
$tab-active: rgba(255, 255, 255, 1);
$blue: #567cfb;
$table-head: #595d6e;
$separator: #ebedf2;
$label-green: #0abbbb;
$svg-icon: rgb(93, 120, 255);
$pink: #fd397a;
$label-form: #646c9a;
$input-form-border: #e2e5ec;
$green: #0abe8c;
$app-light: #36bcff;
$app-dark: #00689c;
$dashboard-tab-text: #74788d;
$dashboard-tab-bg: #ebecf3;
$dashboard-tab-bg-hover: darken($dashboard-tab-bg, 2%);
$dashboard-tab-border: #5d78ff;
$dashboard-tab-separator: #a2a5b924;
$text-orange: #f5600a;
$text-blue: #0064d2;
$text-green: #99cc00;
$text-pink: #fd397a;
