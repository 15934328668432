.main-table {
  width: 100%;
  border-bottom: 1px solid #ebedf2;
  transition: 0.2s;
  &.searching {
    opacity: 0.4;
  }
  &__loader {
    width: 100%;
    padding: 100px;
    text-align: center;
  }
  &__head {
    th {
      cursor: pointer;
      padding: 13px 0 13px 30px;
      text-align: left;
      vertical-align: middle;
      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
      .sort-icons-group {
        display: inline-flex;
        flex-direction: column;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 10px;
        svg {
          margin: 0.7px;
        }
      }
    }
  }
  &__body {
    tr {
      border-top: 1px solid #ebedf2;
      transition: 0.2s;
      &:hover {
        background-color: #f9f9f9;
      }
      td {
        padding: 19px 0 19px 30px;
        text-align: left;
        font-size: 13px;
        line-height: 21px;
        vertical-align: middle;
        color: #595d6e;
      }
    }
  }
  &__products-list {
    transition: 0.2s;
    &.searching {
      opacity: 0.4;
    }
    .main-table:not(.table--inner) {
      border-bottom: none;
      .main-table__body,
      .main-table__head {
        & > tr {
          td,
          th {
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 20%;
            }
          }
        }
      }
      .main-table__body {
        & > tr {
          td:nth-child(1) {
            position: relative;
            padding-left: 60px;
            .button__tree {
              width: 70px;
              height: 60px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
    .inner-table {
      padding: 0 30px 30px;
      border-radius: 4px;
      cursor: default;

      .table {
        background-color: white;
        box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
        tr {
          border-bottom: 1px solid $separator !important;
        }
        &__head--inner {
          tr {
            th {
              padding: 10px;
              padding-bottom: 10px;
              font-weight: 600;
            }
            th:first-child {
              padding-left: 20px;
            }
          }
        }
        &__body--inner {
          td {
            padding: 10px !important;
          }
          td:first-child {
            padding-left: 20px !important;
          }
        }
      }
    }
  }
  &__product {
    cursor: pointer;
    &.active {
      background-color: rgb(249, 249, 249);
    }
    &:hover {
      background-color: rgba(249, 249, 249, 0.7);
    }
    tr {
      border-bottom: none;
      &:hover {
        background-color: initial;
      }
    }
  }
  &__empty {
    padding: 20px;
    p {
      font-size: 13px;
      line-height: 20px;
      color: #595d6e;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 30px 0;
    button {
      margin-left: 15px;
    }
  }
  &__filters {
    padding: 20px 30px;
    border-bottom: 1px solid #ebedf2;
    .filters-grid {
      display: none;
      flex-flow: wrap;
      padding-bottom: 20px;
      &.open {
        display: flex;
      }
    }
    .filers-collapse {
      text-align: right;
      .btn-collapse {
        font-size: 12px;
        line-height: 20px;
        color: #a2a5b9;
        padding: 5px;
        svg {
          max-width: 20px;
          margin-left: 4px;
        }
      }
    }
    .form-group {
      padding-top: 10px;
      flex-basis: 25%;
      max-width: 25%;
      padding-right: 20px;
      min-width: 300px;
      margin-bottom: 0;
      .inp-group {
        input {
          padding: 9px;
          border-radius: 4px;
          border: 1px solid #e2e5ec;
          width: 100%;
          outline: none;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}
