.page-loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 50px;
}

.panel-loader {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
}
