.row {
  display: flex;
  width: calc(100% + 20px);
  margin: 0 -10px;
  &.jc-c {
    justify-content: center;
  }
  &.ai-c {
    align-items: center;
  }
  &.ai-fe {
    align-items: flex-end;
  }
  &.jc-sb {
    justify-content: space-between;
  }
  &.wrap {
    flex-flow: wrap;
  }

  &.no-space {
    width: 100%;
    margin: 0;
  }

  [class^="box"] {
    padding: 0 10px;
  }

  .box-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .box-11 {
    flex-basis: 91.666667%;
    max-width: 91.666667%;
  }

  .box-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .box-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .box-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .box-7 {
    flex-basis: 58.333333%;
    max-width: 58.333333%;
  }

  .box-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .box-5 {
    flex-basis: 41.666667%;
    max-width: 41.666667%;
  }

  .box-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .box-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .box-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .box-1 {
    flex-basis: 8.333333%;
    max-width: 8.333333%;
  }
}

.nowrap {
  white-space: nowrap;
}
