@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap&subset=latin-ext");

.nav-item {
  color: $nav-item;
}

a {
  text-decoration: none;
  color: inherit;
}

.nav-category {
  color: $nav-category;
  text-transform: uppercase;
}

.navbar-text {
  font-size: 12px;
  margin: 0 5px;
  color: #fff;
}

.breadcrumb {
  color: #74788d;
  font-size: 10px;
}

.heading {
  font-size: 15px;
  color: #48465b;
  font-weight: 500;
}

.search-text {
  color: #4950577c;
  font-weight: 400;
  //opacity: 50%;
}

.table-head {
  th {
    color: $table-head;
    font-weight: 500;
    text-align: left;
    padding: 15px;
  }
}

.table-body {
  td {
    color: $table-head;
    font-weight: 400;
    text-align: left;
  }
}

.pagination-info {
  margin-left: 14px;
  color: #646c9a;
  font-weight: 100;
}

.label-form {
  color: $label-form;
}

.platform-name {
  color: $table-head;
  font-weight: 100;
  margin-right: 10px;
}

.country-label {
  color: #a2a5b9;
  font-weight: 100;
}

.popup-text {
  color: #595d6e;
  font-weight: 100;

  strong {
    font-weight: 600;
  }
}

.default-text {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: #595d6e;
  line-height: 20px;
}

.default-text-big {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #48465b;
  line-height: 20px;
}

.default-text-small {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #a2a5b9;
  line-height: 20px;
}

.dashboard-tab-text {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: $dashboard-tab-text;
}

.photo-caption {
  color: #595d6e;
  font-size: 10px;
}

.default-text-dark {
  color: #495057;
}

.sidebar__logo__background {
  width: 33px;
}

.btn-global-image {
  opacity: 0.2;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
}

.btn-neutral-image {
  transition: 0.2s;
  font-size: 9px;
  line-height: 12px;
  background-color: rgba(93, 120, 255, 0.3);
  border-radius: 50%;
  color: #5d78ff;
  width: 16px;
  height: 16px;
  font-weight: 600;
  opacity: 0.3;
  &.active {
    opacity: 1;
  }

  &:hover {
    opacity: 0.8;
  }
}

.mark-counter {
  font-size: 13px;
  line-height: 20px;
  font-weight: 300;
  color: #a2a5b9;
  margin: 5px 0 0 5px;
}

.text-muted {
  color: #a2a5b9;
}

.channel-price-group {
  .form-group.form-group {
    margin-bottom: 0;
  }
}

.text-link {
  color: #5d78ff;
  &:hover {
    text-decoration: underline;
  }
}
