.table-export-status {
  padding: 10px 20px;
  .table-head {
    tr {
      border-top: none;
    }
  }
  .table__body {
    tr {
      &:hover {
        background-color: #fff;
      }
      &:last-child {
        border: none;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;
      }
      .check-group {
        margin: 0;
        text-align: center;
        label {
          width: 25px;
          height: 14px;
        }
      }
      td:nth-last-child(1),
      td:nth-last-child(2) {
        padding-right: 30px;
        label {
          display: none;
        }
      }
    }
  }
}
