.popup-comments {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__list {
    flex-basis: calc(100% - 300px);
    max-width: calc(100% - 300px);
    padding-right: 25px;
    ul {
      margin-bottom: 10px;
    }
    &__item {
      padding: 10px 20px 10px 0;
      position: relative;
      min-height: 50px;
      p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        padding-bottom: 4px;
        color: #000;
        &.author {
          font-size: 8px;
          line-height: 10px;
          color: rgba(162, 165, 185, 1);
        }
      }
      .btn {
        position: absolute;
        top: 10px;
        right: 0;
        padding: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid rgba(235, 237, 242, 1);
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        margin-left: 20px;
      }
    }
  }
  &__content {
    flex-basis: 300px;
    max-width: 300px;
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        margin-left: 10px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #48465b;
      }
    }
    &__text {
      color: #495057;
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        margin-bottom: 10px;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-weight: 600;
      }
      h1 {
        font-size: 18px;
        line-height: 23px;
      }
      h2 {
        font-size: 15px;
        line-height: 20px;
      }
      h3,
      h4,
      h5 {
        font-size: 13px;
        line-height: 20px;
      }
      p {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}
