.prices-group {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  .box {
    &-product {
      flex: 1;
      padding-right: 20px;
    }

    &-labels {
      flex-basis: 150px;
      max-width: 150px;
      padding-right: 20px;
    }

    &-name {
      flex-basis: 270px;
      max-width: 270px;
      padding-right: 20px;
    }

    &-quantity {
      flex-basis: 100px;
      max-width: 100px;
      text-align: center;
      font-size: 13px;
      line-height: 20px;
      color: #a2a5b9;
    }

    &-price {
      flex-basis: 220px;
      max-width: 220px;
    }

    &-additional {
      padding-left: 20px;
      flex-basis: 220px;
      max-width: 220px;
      display: flex;
      align-items: center;
    }
  }

  .bundles-list {
    flex-basis: 100%;
    max-width: 100%;
    padding-top: 15px;

    &__item {
      display: flex;
      align-items: center;
      border-top: 1px solid #ebedf2;
      padding: 10px 0;

      .box-price {
        flex-basis: 120px;
        max-width: 120px;
      }

      .form-group {
        .inp-group {
          .form-span {
            padding: 5px;
            font-size: 10px;
          }

          input {
            font-size: 13px;
            line-height: 20px;
            padding: 5px;
          }
        }
      }
    }
  }
}

.offer-form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn-offer {
    display: inline-block;
    width: 20px;
    opacity: 0.3;
    transition: 0.2s;
    svg {
      max-width: 100%;
    }

    &:hover {
      opacity: 1;
    }
  }
}
