.order-buttons {
  margin-left: 5px;
  display: flex;
  .order-button {
    width: 20px;
    height: 15px;
    border: 1px solid rgba(226, 229, 236, 1);
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    transition: 0.2s;
    [fill] {
      transition: 0.2s;
    }
    svg {
      max-width: 10px;
      max-height: 10px;
    }
    &.up {
      svg {
        transform: scaleY(-1);
      }
    }
    &:hover {
      border-color: #567cfb;
      [fill] {
        fill: #567cfb;
      }
    }
    &:active {
      transform: scale(0.96);
    }
  }
}

.product_card_section {
  margin-bottom: 20px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    border-bottom: 1px solid rgba(235, 237, 242, 1);
    &__title {
      display: flex;
      align-items: center;
      svg{
        [fill]{
          fill: #5d78ff;
        }
      }
      .section_name {
        margin-left: 10px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: rgba(72, 70, 91, 1);
      }
     
    }
    &__action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-basis: 450px;
      max-width: 450px;
      .select-group {
        margin-left: 20px;
        max-width: 190px;
        width: 100%;
        label {
          display: none;
        }
      }
    }
  }
  .text-section-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
    label {
      font-size: 13px;
      line-height: 20px;
      color: #646c9a;
    }
    .btn-comments {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 20px;
      color: #5d78ff;
      .name {
        white-space: nowrap;
        transition: 0.2s;
      }
      .number {
        display: inline-block;
        border-radius: 2px;
        color: #fff;
        font-size: 13px;
        line-height: 15px;
        padding: 2.5px 3px;
        margin-left: 7px;
        white-space: nowrap;
        min-width: 20px;
        text-align: center;
        background-color: #5d78ff;
        &.danger {
          background-color: #fd397a;
        }
      }
      &:hover {
        .name {
          color: #0abe8c;
        }
      }
    }
  }
  .images-box {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 13px;
        line-height: 20px;
        color: #646c9a;
      }
    }
    &__list {
      &__item {
        border-bottom: 1px solid #ebedf2;
        cursor: pointer;
        &.open {
          .images-box__list__item__header {
            cursor: pointer;
            .btn-collapse {
              &::after {
                opacity: 0;
              }
            }
          }
          .images-box__list__item__body {
            display: block;
          }
        }
        &__header {
          display: flex;
          align-items: flex-start;
          position: relative;
          padding: 15px 10px;
          .btn-collapse {
            width: 10px;
            height: 10px;
            border: 1px solid #5d78ff;
            background: #fff;
            position: absolute;
            left: 0;
            top: 20px;
            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: #5d78ff;
            }
            &::before {
              width: 4px;
              height: 1px;
            }
            &::after {
              width: 1px;
              height: 4px;
              opacity: 1s;
              transition: 0.2s;
            }
          }
          .circle-box {
            padding: 0 10px;
          }
          .product-name {
            .name {
              font-size: 12px;
              line-height: 14px;
              font-weight: 300;
              color: #595d6e;
            }
            .sku {
              font-size: 10px;
              line-height: 12px;
              font-weight: 300;
              color: #a2a5b9;
              margin-top: 2px;
            }
          }
        }
        &__body {
          padding-bottom: 15px;
          display: none;
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .section-group {
      &__text {
        flex-basis: calc(60% - 15px);
        max-width: calc(60% - 15px);
      }
      &__image {
        flex-basis: 40%;
        max-width: 40%;
      }
    }
  }
  &.disabled {
    .product_card_section__header {
      position: relative;
      z-index: 10;
    }
    .dashboard__form {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        bottom: 0;
        background: rgba(255, 255, 255, 0.4);
        cursor: not-allowed;
      }
    }
  }
  .dashboard__form__footer {
    justify-content: space-between;
    .box:only-child {
      margin-right: 0;
      margin-left: auto;
    }
    .box {
      display: flex;
      align-items: center;
      .btn {
        margin-left: 20px;
      }
    }
  }
  .relation-box {
    display: flex;
    align-items: flex-start;
    &__name {
      display: flex;
      align-items: center;
      margin-right: 20px;
      svg {
        max-width: 16px;
        margin-right: 10px;
      }
      span {
        font-size: 13px;
        line-height: 20px;
        color: #646c9a;
      }
    }
    &__list {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .section-name {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
  &__add {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
}
