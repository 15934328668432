.popup-gallery {
  margin: auto;
  width: 100%;
  opacity: 0;
  transition: 0.2s;
  padding: 60px;
  overflow-y: scroll;
  max-height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
  &.active {
    opacity: 1;
  }
  &__container {
    background: #fff;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    max-width: 870px;
    margin: auto;
  }
  &__header {
    position: relative;
    padding: 0 30px;
    border-bottom: 1px solid #ebedf2;
    &__tabs {
      display: flex;
      align-items: center;
    }
    &__tab {
      margin-right: 45px;
      &.active {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #5d78ff;
        }
      }
      button {
        display: flex;
        align-items: center;
        padding: 20px 0;
        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;
          color: #48465b;
          margin-left: 10px;
        }
      }
    }
    .btn-close {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 25px;
      height: 25px;
      padding: 0;
      transform: translateY(-50%);
    }
  }
  &-grid {
    display: flex;
    flex-flow: wrap;
    margin-left: -10px;
    margin-top: -10px;
    width: calc(100% + 20px);
    &__item {
      flex-basis: 120px;
      max-width: 120px;
      margin: 10px;
      cursor: pointer;
      position: relative;

      &__image {
        border: 1px solid #e2e5ec;
        border-radius: 4px;
        overflow: hidden;
        height: 120px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &:hover {
          .btn-zoom {
            bottom: 0;
            right: 0;
          }
        }
        .btn-zoom {
          position: absolute;
          transition: 0.2s;
          bottom: -30px;
          right: -30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          padding: 2px;
          background-color: #597dff;
          svg {
            max-width: 100%;
          }
        }
        .image-order {
          position: absolute;
          top: 0;
          right: 0;
          width: 36px;
          height: 36px;
          background: #597dff;
          border-radius: 4px;
          font-size: 15px;
          line-height: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
      &__name {
        font-size: 10px;
        line-height: 13px;
        color: #595d6e;
        word-break: break-all;
        margin-top: 5px;
      }
      &.active {
        .popup-gallery-grid__item__image {
          border-color: #597dff;
        }
      }
    }
  }
}
