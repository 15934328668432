.platform-wrapper {
  min-width: 160px;
}

.product-list {
  flex-basis: 300px;
  max-width: 300px;
  min-height: calc(100vh - 138px);
  background-color: #fff;
  border-right: 1px solid #ebedf2;
  &__item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    min-height: 40px;
    padding: 6px 20px;
    border-bottom: 1px solid #ebedf2;
    .item-icon {
      flex-basis: 24px;
      max-width: 24px;
      svg {
        max-width: 100%;
        [fill] {
          transition: 0.2s;
          fill: #a2a5b9;
        }
      }
    }
    .item-text {
      flex: 1;
      p {
        font-size: 10px;
        line-height: 12px;
        color: #74788d;
      }
      .title {
        font-size: 12px;
        line-height: 14px;
        color: #48465b;
        transition: 0.2s;
        & + p {
          margin-top: 1px;
        }
      }
    }
    &:hover {
      background-color: #f0f3ff;
    }
    &.active {
      background-color: #f0f3ff;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: -1px;
        right: -1px;
        width: 2px;
        background-color: #5d78ff;
      }
      .item-icon {
        svg {
          max-width: 100%;
          [fill] {
            fill: #5d78ff;
          }
        }
      }
      .item-text {
        .title {
          color: #5d78ff;
        }
      }
    }
  }
}

.aside-tabs {
  &__tab {
    border-bottom: 1px solid #ebedf2;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      right: -1px;
      bottom: -1px;
      width: 2px;
      background-color: transparent;
    }
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      top: calc(50% - 4px);
      right: 10px;
      border-radius: 2px;
      background-color: transparent;
    }
    a {
      display: flex;
      align-items: center;
      padding: 8px 30px 8px 20px;
    }
    .icon-holder {
      margin-right: 8px;
    }
    .label-holder {
      min-width: 100px;
      padding-right: 10px;
      .table__platform{
        white-space: nowrap;
        font-size: 10px;
        padding: 0 5px;
        line-height: 12px;
      }
      .table__double-label {
        white-space: nowrap;
        span {
          font-size: 10px;
        }
        .table__label-left {
          padding: 1px 5px;
          line-height: 14px;
        }
        .table__label-right {
          padding: 0 5px;
          line-height: 12px;
        }
      }
    }
    .text-holder {
      p {
        display: inline-flex;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #595d6e;
      }
      .flag-icon {
        min-width: 13px;
        vertical-align: text-top;
      }
    }
    &.active {
      background-color: #f0f3ff;
      &::before {
        background-color: #5d78ff;
      }
      .icon-holder {
        [fill] {
          fill: #5d78ff;
        }
      }
      .text-holder {
        p {
          color: #5d78ff;
        }
      }
    }
    &.warning {
      &::after {
        background-color: #f2ae49;
      }
    }
    &.success {
      &::after {
        background-color: #0abb87;
      }
    }
  }
}

.circle-box {
  padding: 18px;
}

.square {
  border-radius: 2px;
  background-color: #5d78ff;
  display: inline-block;
  margin-left: 10px;
  span {
    color: white;
    display: inline-block;
    padding: 0 5px;
  }
  &--red {
    background-color: #fd397a;
  }
}

.photo-gallery {
  padding: 22px 30px;
  &--section {
    padding: 0;
  }
  .gallery-list {
    &__text-container {
      p {
        line-height: 11px;
        &:first-child {
          margin-bottom: 3px;
        }
      }
    }
    &__item {
      padding: 12px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }
      border-bottom: 1px solid $separator;
      .top {
        display: flex;
        align-items: center;
        .photos-quantity {
          margin-left: auto;
        }
      }
      .bottom {
        margin-top: 20px;
        .photo-box-list {
          display: flex;
          flex-wrap: wrap;
          &__item {
            border: 1px solid $separator;
            border-radius: 4px;
            margin: 5px;
            position: relative;
            overflow: hidden;
            width: 82px;
            max-width: 82px;
            img {
              display: block;
              width: 100%;
            }
            .add-photo-container {
              cursor: pointer;
              text-align: center;
              padding: 12px;
              * {
                display: block;
                text-align: center;
                margin: auto;
              }
              svg {
                margin-bottom: 7px;
              }
              &:hover {
                background-color: rgb(246, 246, 246);
              }
            }
            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              transition: 0.3s;
              cursor: pointer;
              bottom: 0;
              right: 0;
              background-color: none;
              display: flex;
              color: white;
              .overlay-container {
                text-align: center;
              }
              div {
                margin: auto;
                opacity: 0;
                span {
                  font-size: 13px;
                  font-weight: 300;
                }
                path {
                  fill: white;
                }
              }
              button {
                display: block;
                margin: auto;
              }
            }
            &:hover {
              .overlay {
                background-color: #5d78ffd8;
                div {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-group-container {
  .products-list {
    .table:not(.table--inner) {
      .table__body,
      .table-head {
        & > tr {
          td,
          th {
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 10%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              width: 15%;
            }
            &:nth-child(5) {
              width: 15%;
            }
            &:nth-child(6) {
              width: 15%;
            }
          }
        }
      }
    }
  }
}
