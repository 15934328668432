.allegro_channel_form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .box-sm {
    flex-basis: calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
  .box-md {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .checkgroups {
    &-container {
      margin-top: 20px;
      .check-group {
        margin-top: 10px;
      }
    }
    &-label {
      font-size: 13px;
      line-height: 20px;
      color: #646c9a;
    }
  }
}
